import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import find from 'lodash/find';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import BareLayout from '../layouts/bareLayout';
import { PurchaseType } from '../types';
import chev_bot from '../assets/chev_bot.png';
import { populateBasket } from '../services/basket.api';
import { isLoggedIn } from '../services/auth.api';
import { getUserProfile } from '../services/userProfile.api';
import { Profile, ShippingCharge } from '../services/models';

import { BasketLine, HomePageOfferData } from '../services/models';
import { getAllPetsList, getQueryParams, getCheckoutState } from '../utils';
import styles from '../components/Checkout/checkoutForm.module.scss';
import { checkHomePageOffer } from 'services/voucher.api';
import {
  addLine,
  removeLine,
  updateLine,
  useAppDispatch,
  useAppSelector,
  fetchCurrentBasket,
  fetchLines,
} from 'state';
import CustomSpinner from 'components/Spinner';

const CheckoutForm = loadable(() => import('components/Checkout/CheckoutForm'));
const OrderSummary = loadable(() => import('components/Checkout/OrderSummary'));
const Loader = loadable(() => import('components/common/Loader'));
import { Logo } from './../components/common/Logo';

interface AbandonedBasket {
  first_name: string;
  last_name: string;
  email: string;
}

const Checkout = ({ location }: { location: any }) => {
  const {
    total,
    totalExcludingDiscounts,
    dogsName,
    frequency,
    boxCount,
    userFirstName,
    email,
    isTester,
    dogsList: petList,
    subscriptionId,
    isExistingUser,
  } = location.state || getCheckoutState() || {};

  const dispatch = useAppDispatch();
  const basket = useAppSelector(state => state.basket.basket);
  const lines = useAppSelector(state => state.basket.lines);
  const allProducts = useAppSelector(state => state.products.entities);

  const dogsList = getAllPetsList() || petList;
  const queryParams = getQueryParams(location.search);
  const [pageLoading, setPageLoading] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [abandonedBasket, setAbandonedBasket] = useState({} as AbandonedBasket);
  const [userProfile, setUserProfile] = useState(null as Profile);
  const [redirectionText, setRedirectionText] = useState(false);

  const [shippingCharge, setShippingCharge] = useState<string>('0.0');
  const [ruralDelivery, setRuralDelivery] = useState<string>('0.0');
  const [homePageOffer, sethomePageOffer] = useState({} as HomePageOfferData);

  const productInfo = lines?.length
    ? find(allProducts, { url: lines[0].product })
    : {};

  const [isSticky, setSticky] = useState(false);
  const toggleSummaryModal = () => {
    setShowSummaryModal(!showSummaryModal);
  };

  const fetchData = async () => {
    if (basket) {
      const shippingCharge = basket.base_shipping_charge != null
        ? basket.base_shipping_charge.toString()
        : "0.00"; // Default to "0.00" if null or undefined
      setShippingCharge(shippingCharge);
    } else {
      setShippingCharge("0.00"); // Fallback if basket itself is undefined
    }
    try {
    const apiResponse = await checkHomePageOffer();
    sethomePageOffer(
      apiResponse.data ? apiResponse.data : ({} as HomePageOfferData),
    );
    } catch (error) {
      console.error("Error fetching homepage offer:", error);
      sethomePageOffer(({} as HomePageOfferData));
    };
  };

  const updateProfileData = async () => {
    if (isLoggedIn()) {
      setUserProfile(await getUserProfile());
    }
    await dispatch(fetchCurrentBasket());
    await dispatch(fetchLines());
    await fetchData();
  };

  useEffect(() => {
    setPageLoading(false);
  }, []);

  useEffect(() => {
    const basketToken = queryParams.token;
    (async () => {
      if (basketToken) {
        const { data, error } = await populateBasket(basketToken);
        if (error) {
          console.error('Error repopulating the basket', error);
          navigate('/');
        } else {
          setAbandonedBasket(data || {});
        }
      }
      updateProfileData();
    })();
  }, []);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < -10;
      if (isShow !== isSticky) setSticky(isShow);
    },
    [isSticky],
  );

  const changeQuantity = async (
    basketId: number,
    basketLine: BasketLine,
    changeBy: number,
  ) => {
    const newQuantity = basketLine.quantity + changeBy;
    if (newQuantity === 0) {
      await dispatch(removeLine(basketLine.id));
    } else {
      await dispatch(
        updateLine({
          lineId: basketLine.id,
          quantity: basketLine.quantity + changeBy,
          frequency: basketLine.frequency,
        }),
      );
    }
  };

  const addProduct = async (
    productUrl: string,
    petId: number,
    purchaseType?: PurchaseType,
  ) => {
    await dispatch(
      addLine({
        petId,
        url: productUrl,
        quantity: 1,
        purchaseType: purchaseType,
      }),
    );
  };

  const handleShippingChargeChange = (shippingCharge: ShippingCharge) => {
    setShippingCharge(shippingCharge.incl_tax);
    setRuralDelivery(shippingCharge.rural_delivery);
  };

  const renderStickyLogo = () => {
    return (
      <>
        <div
          className="chk-mb-sl-or"
          onClick={toggleSummaryModal}
          style={{ top: isSticky ? 0 : '0px', left: 0 }}
        >
          <Logo />
        </div>
      </>
    );
  };

  const renderStickyHeader = () => {
    return (
      <>
        <div
          className="chk-mb-sl-or"
          onClick={toggleSummaryModal}
          style={{ top: isSticky ? 0 : '0px', left: 0 }}
        >
          <Logo />
          <span>View Order Summary</span>
          <img src={chev_bot} alt="" />
        </div>
        <div
          className={classNames({
            [styles.summaryModalContainer]: true,
            [styles.open]: showSummaryModal,
          })}
        >
          <OrderSummary
            ruralDelivery={ruralDelivery}
            dogsName={dogsName}
            showPayNow={false}
            basket={basket}
            shippingCharge={shippingCharge}
            changeQuantity={changeQuantity}
            setPageLoading={setPageLoading}
            addProduct={addProduct}
            isTester={isTester}
            boxCount={boxCount}
            productDetails={productInfo}
            dogsList={dogsList}
            allProducts={allProducts}
            loading={pageLoading}
            homePageOffer={homePageOffer}
          />
        </div>
      </>
    );
  };

  const stripePromise = loadStripe(
    process.env.STRIPE_PUBLISHABLE_KEY as string,
  );
  useEffect(() => {
    const isExistingCard = localStorage.getItem('is_existing_card');
    setRedirectionText(isExistingCard === 'true');
  })
  if (!lines) {
    return <Loader />;
  } else {
    return (
      <CustomSpinner
        disableBodyScroll={true}
        blurValue={5}
        isLoading={pageLoading}
        extras={'ifScroll'}
        isSpinner={false}
        texts={redirectionText ? [
          'Processing the order.',
          'Processing the order..',
          'Processing the order...',
          'Processing the order....',
        ] : [
          'Redirecting to WindCave Checkout Page.',
          'Redirecting to WindCave Checkout Page..',
          'Redirecting to WindCave Checkout Page...',
          'Redirecting to WindCave Checkout Page....',
        ]}
      >
        <BareLayout showDiscountHeader={false}>
          <div className="checkout-wrapper">
            <div className="container-fluid">
              {!isLoggedIn() ? renderStickyHeader() : renderStickyLogo()}
              <div className="checkout-contents def-w-max">
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <CheckoutForm
                        stripe={stripe}
                        elements={elements}
                        frequency={frequency}
                        boxCount={boxCount}
                        dogsName={dogsName}
                        total={total}
                        totalExcludingDiscounts={totalExcludingDiscounts}
                        userFirstName={
                          abandonedBasket.first_name || userFirstName
                        }
                        userLastName={abandonedBasket.last_name || ''}
                        email={abandonedBasket.email || email}
                        showSummaryModal={showSummaryModal}
                        handleShippingChargeChange={handleShippingChargeChange}
                        changeQuantity={changeQuantity}
                        setPageLoading={setPageLoading}
                        addProduct={addProduct}
                        fetchBasketAgain={fetchData}
                        isTester={isTester}
                        dogsList={dogsList}
                        productDetails={productInfo}
                        allProducts={allProducts}
                        homePageOffer={homePageOffer}
                        userProfile={userProfile}
                        ot={queryParams.ot || 'new'}
                        subscriptionId={subscriptionId}
                        updateProfileData={updateProfileData}
                        isExistingUser={isExistingUser}
                        initiateLoading={(e: boolean) => {
                          setPageLoading(e);
                        }}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>

                {/* <CheckoutNotice /> */}
              </div>
            </div>
          </div>
        </BareLayout>
      </CustomSpinner>
    );
  }
};

export default Checkout;
